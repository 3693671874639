import { Injectable, EventEmitter } from '@angular/core';
@Injectable()
export class AppService {
  public loader: any = null;
  public _loading: Boolean = true;
  public isLoaded: EventEmitter<boolean> = new EventEmitter();
  constructor() {
    this.loader = document.getElementById('loader');
  }
  get loading() {
    return this._loading;
  }

  set loading(loading ) {
    setTimeout(() => {
      this._loading = loading;
      if (loading) {
        this.loader.style.display = 'block';
      } else {
        this.isLoaded.emit(true);
        this.loader.style.display = 'none';
      }
    }, 1);
  }
}
