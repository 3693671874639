import { Injectable } from '@angular/core';
import { FormioAuthService, FormioAuthConfig } from '@formio/angular/auth';
import { AppConfig } from './config';
import get from 'lodash/get';

@Injectable()
export class AuthService extends FormioAuthService {
  constructor(
    public appConfig: AppConfig,
    public config: FormioAuthConfig
  ) {
    super(appConfig, config);
    this.appConfig.form.subscribe(() => {
      this.loginForm = this.appConfig.appUrl + '/' + get(this.config, 'login.form', 'user/login');
    });
  }
}
