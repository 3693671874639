import { EventEmitter, Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { AppService } from './app.service';
import { Formio, FormioUtils } from '@formio/angular';
import _get from 'lodash/get';
import _each from 'lodash/each';
import _trim from 'lodash/trim';
import _isObject from 'lodash/isObject';
require('seamless/build/seamless.child.js');
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import premium from '@formio/premium';
import {TranslateService} from '@ngx-translate/core';

// Default all of our boolean properties.
const setDefaultProps = (queryObj: any) => {
  [
    {name: 'iframe', default: false},
    {name: 'header', default: true},
    {name: 'branding', default: true}
  ].forEach((prop) => {
    if (queryObj.hasOwnProperty(prop.name)) {
      queryObj[prop.name] = (queryObj[prop.name] !== false) && (queryObj[prop.name] !== '0') && (queryObj[prop.name] !== 'false');
    } else {
      queryObj[prop.name] = prop.default;
    }
  });
  return queryObj;
};

let query: any = setDefaultProps(Formio.pageQuery());
if ((window as any).SAC_ENABLED) {
  Formio.requireLibrary(
    'protected-eval',
    'protected-eval',
    'assets/lib/protected-eval/protected-eval.js',
    true
  ).then((protectedEval) => {
    (Formio as any).use(protectedEval);
  });
}
if ((window as any).VPAT_ENABLED) {
  (Formio as any).license = true;
  Formio.requireLibrary('uswds-core', 'uswds-core', [
    { type: 'styles', src: 'assets/lib/uswds/css/uswds.min.css' }
  ]);
  Formio.requireLibrary('uswds-css', 'uswds-css', [
    { type: 'styles', src: 'assets/lib/formio-uswds/uswds.min.css' }
  ]);
  Formio.requireLibrary('vpat', 'vpat', 'assets/lib/vpat/vpat.min.js', true).then((vpat) => {
    (Formio as any).use(vpat);
    Formio.requireLibrary('uswds', 'uswds', 'assets/lib/formio-uswds/uswds.min.js', true).then((uswds) => {
      (Formio as any).use(uswds);
    });
  });
}

const DEFAULT_API = 'https://api.form.io';
const EVERYONE = '000000000000000000000000';

@Injectable()
export class AppConfig {
  static parent;
  static apiUrl;
  static appUrl;
  static projectConfig = (window as any).PROJECT || {config: {}, public: {}};
  static sso = _get(AppConfig.projectConfig, 'config.sso', false);
  static ssoProject = _get(AppConfig.projectConfig, 'config.ssoProject', false);
  static appJS = _get(AppConfig.projectConfig, 'config.js', '');
  static appCSS = _get(AppConfig.projectConfig, 'config.css', '');
  static logo = _get(AppConfig.projectConfig, 'config.logo', '');
  static logoHeight = _get(AppConfig.projectConfig, 'config.logoHeight', '30px');
  static navbar = _get(AppConfig.projectConfig, 'config.navbar', 'navbar-light bg-light');
  static query;
  static formPath;
  static projectName;
  static path;
  static src;
  static ready: Promise<any>;
  static lang;

  public path: string;
  public src: string;
  public project: string;
  public projectConfig: any = AppConfig.projectConfig;
  public query: any;
  public parent: any;
  public appCSS: SafeResourceUrl;
  public appJS: SafeResourceUrl;
  public appLogo: SafeResourceUrl;
  public sso = AppConfig.sso;
  public ssoProject = AppConfig.ssoProject;
  public logoHeight = AppConfig.logoHeight;
  public titleStyle: any;
  public navbar = AppConfig.navbar;
  public branding = ((window as any).APP_BRANDING !== false);
  public message: string;
  public title: string;
  public formName: string;
  public formPath: string;
  public currentForm: any;
  public formio: any;
  public form: EventEmitter<object> = new EventEmitter();
  public submissions: string;
  public offlinePlugin;
  public needsRefresh = false;
  public plan;
  public defaultLang = 'en';
  public translationPath;
  public translations;
  public lang: string;

  static initialize(config?: any) {
    query = setDefaultProps(Formio.pageQuery());
    // Connect to the iframe parent (if applicable).
    AppConfig.parent = (window as any).seamless.connect({
      container: 'div.main'
    });

    // Add custom css file if applicable.
    if (query.css) {
      AppConfig.appCSS = decodeURIComponent(query.css);
    }

    // Add custom js file if applicable.
    if (query.js) {
      AppConfig.appJS = decodeURIComponent(query.js);
    }
    let protocol;
    let host;
    let parser: any;
    if (query.formreset) {
      localStorage.removeItem(`${Formio.namespace}Title`);
      localStorage.removeItem(`${Formio.namespace}FormName`);
      localStorage.removeItem(`${Formio.namespace}FormPath`);
      localStorage.removeItem('formioSrc');
      localStorage.removeItem('formioBase');
      localStorage.removeItem('currentPath');
    }
    if (query.lang) {
      AppConfig.lang = query.lang || 'en';
    }
    AppConfig.path =  query.reset ? undefined : localStorage.getItem('currentPath');
    AppConfig.src = query.reset ? undefined : localStorage.getItem('formioSrc');
    AppConfig.apiUrl = query.reset ? DEFAULT_API : localStorage.getItem('formioBase') || DEFAULT_API;
    AppConfig.appUrl = query.reset ? AppConfig.apiUrl : localStorage.getItem('formioProject') || AppConfig.apiUrl;
    if (query.src) {
      AppConfig.src = query.src;
      parser = document.createElement('a');
      parser.href = query.src;
      protocol = parser.protocol.replace(':', '');
      host = parser.host;
      AppConfig.apiUrl = protocol + '://' + host;
      const hostParts = host.split('.');
      const pathParts = _trim(parser.pathname, '/').split('/');
      AppConfig.formPath = pathParts.pop();
      AppConfig.appUrl = pathParts.length ? `${AppConfig.apiUrl}/${pathParts.join('/')}` : AppConfig.apiUrl;
      AppConfig.projectName = pathParts.length ? pathParts.pop() : hostParts[0];
      AppConfig.path = `${AppConfig.projectName}/${AppConfig.formPath}`;
      localStorage.setItem('formioSrc', AppConfig.src);
      localStorage.setItem('currentPath', AppConfig.path);
    } else if (query.paths.length > 1 && query.paths[0] !== 'auth') {
      if ((window as any).PROJECT_URL) {
        AppConfig.appUrl = (window as any).PROJECT_URL;
        AppConfig.projectName = query.paths[0];
        AppConfig.formPath = query.paths[1];
        parser = document.createElement('a');
        parser.href = AppConfig.appUrl;
        protocol = parser.protocol.replace(':', '');
        // The port number is not included because 443 is the scheme's default port
        // But IE 11 includes it.
        host = parser.host.replace(':443', '');
        AppConfig.apiUrl = protocol + '://' + host;
        AppConfig.src = `${AppConfig.appUrl}/${AppConfig.formPath}`;
        AppConfig.path = `${AppConfig.projectName}/${AppConfig.formPath}`;
        localStorage.setItem('formioSrc', AppConfig.src);
        localStorage.setItem('currentPath', AppConfig.path);
      } else if (!AppConfig.src) {
        AppConfig.projectName = query.paths[0];
        AppConfig.formPath = query.paths[1];
        if (AppConfig.appUrl === AppConfig.apiUrl) {
          AppConfig.appUrl = `https://${AppConfig.projectName}.form.io`;
        }
        AppConfig.src = `${AppConfig.appUrl}/${AppConfig.formPath}`;
        AppConfig.path = `${AppConfig.projectName}/${AppConfig.formPath}`;
        if (query.host) {
          protocol = query.protocol ? query.protocol : 'https' ;
          host = query.host;
          AppConfig.apiUrl = protocol + '://' + host;
          AppConfig.appUrl = `${AppConfig.apiUrl}/${AppConfig.projectName}`;
          AppConfig.path = `${AppConfig.projectName}/${AppConfig.formPath}`;
        }
        localStorage.setItem('formioSrc', AppConfig.src);
        localStorage.setItem('currentPath', AppConfig.path);
      }
    }
    localStorage.setItem('formioBase', AppConfig.apiUrl);
    localStorage.setItem('formioProject', AppConfig.appUrl);

    // Namespace this viewer based on the project currently being viewed.
    Formio.namespace = `viewer-${btoa(AppConfig.appUrl).replace('/', '-').replace('+', '_').replace('=', '')}`;
    Formio.setBaseUrl(AppConfig.apiUrl);
    Formio.setProjectUrl(AppConfig.appUrl);
    if (query.token) {
      localStorage.setItem(`${Formio.namespace}Token`, query.token);
      localStorage.removeItem(`${Formio.namespace}AppUser`);
      localStorage.removeItem(`${Formio.namespace}User`);
    }

    // Add bootswatch theme.
    const currentTheme = query.theme || localStorage.getItem(`${Formio.namespace}Theme`) || 'cosmo';
    localStorage.setItem(`${Formio.namespace}Theme`, currentTheme);
    AppConfig.applyTheme(currentTheme);

    // Set the correct properties on the AppConfig instance.
    if (config) {
      config.path = AppConfig.path;
      config.project = AppConfig.appUrl;
      config.src = AppConfig.src;
    }

    if (AppConfig.sso || query.sso || query.saml) {
      Formio.authUrl = AppConfig.ssoProject;
      AppConfig.ready = Formio.ssoInit(AppConfig.sso || query.sso || 'saml', {
        forceAuth: true
      }).then(() => {
        window.location.href = `${window.location.href}${AppConfig.path}`;
      }).catch((err) => {
        console.log(err);
      }) || (new Promise(() => {}));
    } else {
      AppConfig.ready = Promise.resolve();
    }

    // Set the application title.
    const appTitle = _get(AppConfig.projectConfig, 'config.title', '');
    if (appTitle) {
      document.title = appTitle;
    }

    // Load the form module.
    const formModuleConfig = _get(AppConfig.projectConfig, 'public.formModule', '');
    if (formModuleConfig) {
      let formModule = null;
      try {
        /* tslint:disable:no-eval */
        eval(`formModule = ${formModuleConfig}`);
        /* tslint:enable:no-eval */
      } catch (err) {
        console.warn(err.message);
        formModule = null;
      }
      if (formModule) {
        (Formio as any).use(formModule);
      }
    }

    return AppConfig.src;
  }

  static applyTheme(themeName = 'cosmo') {
    const styles = `assets/lib/bootswatch/dist/${themeName}/bootstrap.min.css`;
    const head = document.getElementsByTagName('head')[0];
    const themeLink = document.getElementById(
      'client-theme'
    ) as HTMLLinkElement;

    if (themeLink) {
      themeLink.setAttribute('href', styles);
    } else {
      const link = document.createElement('link');
      link.setAttribute('id', 'client-theme');
      link.setAttribute('rel', 'stylesheet');
      link.setAttribute('href', styles);
      head.appendChild(link);
    }
  }

  constructor(
    private router: Router,
    public toastr: ToastrService,
    private sanitizer: DomSanitizer,
    public appService: AppService,
    public translateService: TranslateService
  ) {
    this.title = localStorage.getItem(`${Formio.namespace}Title`);
    this.formName = localStorage.getItem(`${Formio.namespace}FormName`);
    this.formPath = localStorage.getItem(`${Formio.namespace}FormPath`);
    this.appJS = this.sanitizer.bypassSecurityTrustResourceUrl(AppConfig.appJS);
    this.appCSS = this.sanitizer.bypassSecurityTrustResourceUrl(AppConfig.appCSS);
    if (AppConfig.logo) {
      this.appLogo = this.sanitizer.bypassSecurityTrustResourceUrl(AppConfig.logo);
    } else if (AppConfig.apiUrl === DEFAULT_API) {
      this.appLogo = this.sanitizer.bypassSecurityTrustResourceUrl('assets/logo.png');
    }

    // Disable the branding if they provide it in the query parameters.
    if (query.branding === false) {
      this.branding = false;
    }
    if ((window as any).PROJECT && (window as any).PROJECT.config) {
      this.applyProjectConfig((window as any).PROJECT.config);
    } else if (AppConfig.appUrl !== DEFAULT_API) {
      Formio.fetch(`${AppConfig.appUrl}/config.json`)
        .then((response) => response.json())
        .then((projectConfig) => {
          this.applyProjectConfig(projectConfig?.config);
        });
    }
    this.path = AppConfig.path;
    this.src = AppConfig.src;
    this.project = AppConfig.appUrl;
    this.query = query;
    this.parent = AppConfig.parent;
    this.getPlan().then((plan: string) => {
      if (['team', 'commercial'].indexOf(plan) < 0) {
        console.log('isBasic');
      } else {
        (Formio as any).use(premium);
      }
    });
  }

  applyProjectConfig(config: { [key: string]: any }) {
    if (!_isObject(config)) {
      return;
    }
    if (config.title) {
      document.title = config.title;
    }
    if (config.logoHeight) {
      this.logoHeight = config.logoHeight;
    }
    if (config.titleStyle) {
      this.titleStyle = config.titleStyle;
    }
    if (config.logo) {
      this.appLogo = this.sanitizer.bypassSecurityTrustResourceUrl(config.logo);
    }
    if (config.favicon) {
      const favicon = document.getElementById(`favicon`);
      if (favicon) {
        (favicon as any).href = config.favicon;
      }
    }
    if (config.hasOwnProperty('branding')) {
      this.branding = config.branding;
    }
    if (config.hasOwnProperty('message')) {
      this.message = config.message;
    }
    if (config.js) {
      const script = document.createElement('script');
      script.type = 'text/javascript';
      script.src = config.js;
      document.getElementsByTagName('head')[0].appendChild(script);
    }
    if (config.css) {
      this.appCSS = this.sanitizer.bypassSecurityTrustResourceUrl(config.css);
    }
    if (config.navbar) {
      this.navbar = config.navbar;
    }
    // Default Lang is "en" can be override from settings
    if (config.defaultLang) {
      this.defaultLang = config.defaultLang;
    }
    // Lang from settings
    if (config.lang) {
      this.lang = config.lang;
    }
    // Lang from Params.
    if (AppConfig.lang) {
      this.lang = AppConfig.lang;
      localStorage.setItem('lang', AppConfig.lang);
    }
    // Resource Path for Translation
    if (config.translationPath && this.lang) {
      this.translateService.setDefaultLang(this.defaultLang);
      this.translateService.use(this.lang);
      this.translationPath = config.translationPath;
      Formio.fetch(`${AppConfig.appUrl}/${this.translationPath}/submission`)
        .then(res => (res.json())).then(translations => {
        this.translations = translations;
        if (this.translations.length > 0) {
          this.translations.forEach(translation => {
            const words = {};
            for (const lang in translation.data.translations) {
              if (translation.data.translations.hasOwnProperty(lang)) {
                words[lang] = translation.data.translations[lang];
              }
            }
            this.translateService.setTranslation(translation.data.language, words);
          });
        }
      });
    }
  }

  reload(event?: any) {
    if (event) {
      event.preventDefault();
    }
    window.location.reload();
  }

  submitAlter(submission: any) {
    if (!submission.metadata) {
      submission.metadata = {};
    }
    submission.metadata.appUrl = location.href;
    FormioUtils.eachComponent(this.currentForm.components, (component, path) => {
      if (
        component &&
        component.type === 'button' &&
        component.properties &&
        _get(submission.data, path, false)
      ) {
        _each(component.properties, (prop, key) => {
          if (key.substring(0, 6) === 'data__') {
            submission.data[key.substring(6)] = prop;
          }
        });
      }
    });
    return submission;
  }

  loadForm(noSubmitCheck?: boolean, route?: ActivatedRoute) {
    if (window.location.hash.includes('submission') && (query && !query.reset && !query.formreset && !query.src)) {
      localStorage.setItem('formRedirect', window.location.href);
    }
    const src = AppConfig.src;
    if (AppConfig.initialize(this) !== src) {
      // If the form changes, then logout and refresh.
      if (!localStorage.getItem(`formioProject`) || localStorage.getItem(`formioProject`) !== this.project) {
        localStorage.removeItem(`${Formio.namespace}Token`);
        localStorage.removeItem(`${Formio.namespace}AppUser`);
        localStorage.removeItem(`${Formio.namespace}User`);
        window.location.reload();
        return;
      }
      AppConfig.initialize();
      window.location.reload();
    }
    this.submissions = `${AppConfig.src}/submission`;
    this.formio = new Formio(AppConfig.src);
    let currentForm = null;
    return AppConfig.ready
      .then(() => this.formio.loadForm(null, {
        noToken: this.offlinePlugin && this.offlinePlugin.offline
      }))
      .then((formObj) => {
        currentForm = formObj;
        this.currentForm = formObj;
        this.title = formObj.title;
        this.formName = formObj.name;
        this.formPath = formObj.path;
        AppConfig.formPath = formObj.path;
        localStorage.setItem(`${Formio.namespace}Title`, this.title);
        localStorage.setItem(`${Formio.namespace}FormName`, this.formName);
        localStorage.setItem(`${Formio.namespace}FormPath`, this.formPath);
        return this.formio.userPermissions(undefined, formObj).then((perms) => {
          const setRolesProperties = (canSubmit = true) => {
            const accessRoles = {};

            // Translate the role ids to the role names.
            for (const roleName in perms.access.roles) {
              if (perms.access.roles.hasOwnProperty(roleName)) {
                accessRoles[perms.access.roles[roleName]._id] = roleName;
              }
            }

            // Get the current user roles in terms of the role name.
            const userRoles = perms.user.roles.map((roleId) => {
              return accessRoles[roleId];
            });

            // Apply custom properties to components based on settings.
            const props = [
              { prop: 'hidden', key: 'userHide', value: true },
              { prop: 'hidden', key: 'userShow', value: false },
              { prop: 'disabled', key: 'userDisable', value: true },
              { prop: 'disabled', key: 'userEnable', value: false }
            ];
            FormioUtils.eachComponent(formObj.components, (component) => {
              if (component.properties) {
                props.forEach((prop) => {
                  if (component.properties[prop.key]) {
                    component[prop.prop] = !prop.value;
                    const roles = component.properties[prop.key].split(',');
                    for (const role of roles) {
                      if (userRoles.indexOf(_trim(role)) !== -1) {
                        component[prop.prop] = prop.value;
                        break;
                      }
                    }
                  }
                });
              }
              if (!canSubmit && component.type === 'button' && component.key === 'submit') {
                component.disabled = true;
              }
            }, true);
            formObj.disableWizardSubmit = !canSubmit;
          };

          if (!noSubmitCheck && !perms.create) {
            if (!perms.user._id || (perms.user._id && !perms.read)) {
              let readRoles = perms.form.access.find((perm) => perm.type === 'read_all');
              readRoles = readRoles ? readRoles.roles : [];

              let createRoles = perms.form.submissionAccess.filter((access) => access.type.match('create'));
              createRoles = createRoles.reduce((acc, access) => {
                if (access && access.roles) {
                  acc.push(...access.roles);
                }
                return acc;
              }, []);

              const hasEveryone = createRoles.includes(EVERYONE);
              const isReadAllow = readRoles.some((role) => perms.user.roles && perms.user.roles.includes(role) || role === EVERYONE);

              Formio.setUser(null);

              if (isReadAllow) {
                setRolesProperties(hasEveryone);
                this.currentForm = formObj;
                this.form.emit(formObj);
                return formObj;
              } else {
                this.toastr.error('You do not have access to submit this form. Please login with a different account.');
                this.router.navigate(['/auth/login']);
              }
            } else if (perms.read) {
              this.router.navigate([`submission`], {relativeTo: route});
            } else {
              return false;
            }
          } else {
            setRolesProperties();
            this.currentForm = formObj;
            this.form.emit(formObj);
            return formObj;
          }
        });
      })
      .then((formObj) => {
        this.appService.loading = false;
        return formObj;
      })
      .catch((err) => {
        if (err === 'Unauthorized' && !err.networkError) {
          const currentUser = Formio.getUser();
          if (!currentUser) {
            this.router.navigate(['/auth/login']);
          }
        }
        // If we have an offline plugin and are offline, then just show the current form.
        if (this.offlinePlugin && this.offlinePlugin.offline) {
          this.currentForm = currentForm;
          this.form.emit(currentForm);
          return currentForm;
        }

        this.toastr.error(err.message || err);
        this.appService.loading = false;
        return false;
      });
  }

  /**
   * Reset all stored variables.
   */
  reset() {
    // Remove global items.
    localStorage.removeItem('formioSrc');
    localStorage.removeItem('formioBase');
    localStorage.removeItem('formioProject');
    localStorage.removeItem('currentPath');

    // Remove namespaced app items.
    localStorage.removeItem(`${Formio.namespace}Title`);
    localStorage.removeItem(`${Formio.namespace}FormName`);
    localStorage.removeItem(`${Formio.namespace}FormPath`);
    localStorage.removeItem(`${Formio.namespace}Theme`);

    // Remove namespaced Formio items.
    localStorage.removeItem(`${Formio.namespace}Token`);
    localStorage.removeItem(`${Formio.namespace}User`);
    localStorage.removeItem(`${Formio.namespace}AppUser`);
  }

  getPlan()  {
    this.plan = 'commercial';
    return new Promise((resolve, reject) => {
      // We will temporarily resolve commercial always here until we have the official licensing in place.
      return resolve(this.plan);
    });
  }

  get appUrl() {
    return AppConfig.appUrl;
  }

  get apiUrl() {
    return AppConfig.apiUrl;
  }

  resetSamlToken() {
    if (this.query.saml) {
      this.query.saml = null;
    }
  }
}

// Initialize the app config.
AppConfig.initialize();
