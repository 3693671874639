import { Component, ChangeDetectorRef, ElementRef, ViewChild, AfterViewInit } from '@angular/core';
import { FormioAuthService } from '@formio/angular/auth';
import { Formio } from '@formio/angular';
import { Router } from '@angular/router';
import { AppConfig } from './config';
import { OfflineService } from './offline/offline.service';
import { ToastrService } from 'ngx-toastr';
import { AppService } from './app.service';
const _get = require('lodash/get');
import * as packageJSON from '../../package.json';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements AfterViewInit {
  @ViewChild('offline', {static: false}) offline: ElementRef;
  public isOnline = true;
  public hasNavBar = true;
  public appVersion = (packageJSON as any).version;
  public rendererVersion = (packageJSON as any).dependencies.formiojs.replace('^', '');
  public copyrightYear = new Date().getFullYear();
  constructor(
    public auth: FormioAuthService,
    public router: Router,
    public config: AppConfig,
    public offlineService: OfflineService,
    public ref: ChangeDetectorRef,
    public toastr: ToastrService,
    public appService: AppService,
    public translate: TranslateService
  ) {
    // Initialize the authentication.
    this.auth.init();
    this.config.getPlan().then((plan: string) => {
      if (['team', 'commercial'].indexOf(plan) < 0) {
        this.toastr.warning('Limited Pro Version');
        if (this.auth.authenticated) {
          this.auth.logout();
          this.router.navigate([`/${this.config.path}`]);
        }
      } else {
        this.offlineService.onlineChange.subscribe((online) => {
          this.isOnline = online;
          this.ref.detectChanges();
        });
      }

      this.auth.onLogin.subscribe(() => {
        if (['team', 'commercial'].indexOf(plan) < 0) {
          this.auth.logout();
          this.toastr.error('Limited Pro Version only Allow Anonymous Submissions, please contact support@form.io');
        } else {
          if (localStorage.getItem('formRedirect')) {
            window.location.href = localStorage.getItem('formRedirect');
          } else {
            this.router.navigate([`/${this.config.path}`]);
          }
        }
      });

      this.auth.onLogout.subscribe(() => {
        this.config.resetSamlToken();
        this.router.navigate(['/auth/login']);
      });

      // There isn't a navbar if the header is explicitely set to to false or iframe is set to true.
      if (
        !_get(this.config, 'query.header', true) ||
        _get(this.config, 'query.iframe', false)
      ) {
        this.hasNavBar = false;
      }
    });
    Formio.events.on('formio.user', (event, data) => {
      if (event && !this.auth.user) {
        this.auth.setUser(event);
      }
    });
    if (localStorage.getItem('lang')) {
      this.config.lang = localStorage.getItem('lang');
    }
  }

  refreshApp() {
    this.config.reset();
    window.location.href = `${location.origin}/#/`;
    this.config.reload();
  }

  goToSubmissions() {
    if (['team', 'commercial'].indexOf(this.config.plan) < 0) {
      this.toastr.error('Limited Pro Version only Allow Anonymous Submissions, please contact support@form.io');
    } else {
      this.router.navigate([`/${this.config.path}/submission`]);
    }
  }
  goToOffline() {
    if (['team', 'commercial'].indexOf(this.config.plan) < 0) {
      this.toastr.error('Limited Pro Version only Allow Anonymous Submissions, please contact support@form.io');
    } else {
      this.router.navigate([`/${ this.config.path }/offline`]);
    }
  }

  goToForm() {
    this.router.navigate([`/${ this.config.path }`]);
  }

  ngAfterViewInit() {
    this.appService.isLoaded.subscribe(() => {
      if (this.auth.authenticated) {
        localStorage.removeItem('formRedirect');
      }
      setTimeout(() => {
        if (this.offline) {
          this.offlineService.offlinePlugin.statusIndicator(this.offline.nativeElement, true);
        }
      }, 200);
    });
  }
}
