import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { AppConfig } from '../config';
import { AppService } from '../app.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  @ViewChild('formsrc', {static: true}) formSrcField: ElementRef;
  @ViewChild('copy') copy: ElementRef;
  public formSrc: string;
  public formObj: any;
  public shareUrl: string;
  public alert: string;
  public theme = 'cosmo';
  public src = '';
  public options: any = { disableAlerts: true, noAlerts: false };
  public themes: any = [
    { label: 'Cosmo', name: 'cosmo' },
    { label: 'Yeti', name: 'yeti' },
    { label: 'Materia', name: 'materia' },
    { label: 'Cerulean', name: 'cerulean' },
    { label: 'Cyborg', name: 'cyborg' },
    { label: 'Darkly', name: 'darkly' },
    { label: 'Flatly', name: 'flatly' },
    { label: 'Journal', name: 'journal' },
    { label: 'Litera', name: 'litera' },
    { label: 'lumen', name: 'lumen' },
    { label: 'Lux', name: 'lux' },
    { label: 'Minty', name: 'minty' },
    { label: 'Pulse', name: 'pulse' },
    { label: 'Sandstone', name: 'sandstone' },
    { label: 'Simplex', name: 'simplex' },
    { label: 'Sketchy', name: 'sketchy' },
    { label: 'Slate', name: 'slate' },
    { label: 'Solar', name: 'solar' },
    { label: 'Spacelab', name: 'spacelab' },
    { label: 'Superhero', name: 'superhero' },
    { label: 'United', name: 'united' }
  ];
  public isWatchSubErrors = true;
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    public config: AppConfig,
    public appService: AppService
  ) {}

  ngOnInit() {
    // If a src is provided, then we can navigate to the correct page.\
    if (this.config.query.src) {
      if (!AppConfig.projectName) {
        this.alert = 'You must provide a project name within the url.';
        return;
      }
      if (!AppConfig.formPath) {
        this.alert = 'You must provide a Form embed URL to display a form.';
        return;
      }
      this.router.navigate([`${AppConfig.projectName}/${AppConfig.formPath}`], {relativeTo: this.route});
    } else {
      this.appService.loading = false;
    }
  }

  onTheme(event: any) {
    this.theme = event.target.value;
    this.setShare();
  }

  onFormLoad(form: any) {
    this.formObj = form;
  }

  setShare() {
    this.shareUrl = `${location.origin}/#/?src=${encodeURIComponent(this.src)}&theme=${this.theme}`;
  }

  gotoForm() {
    this.config.reset();
    window.location.href = this.shareUrl;
    window.location.reload();
  }

  onChange(event: any) {
    this.src = event.target.value;
    this.setShare();
  }

  showForm() {
    this.src = this.formSrc = this.formSrcField.nativeElement.value;
    this.setShare();
    AppConfig.applyTheme(this.theme);
  }

  copyToClipboard() {
    this.copy.nativeElement.select();
    document.execCommand("copy");
    this.alert = 'Copied to clipboard';
  }
}
