import { NgModule } from '@angular/core';
import { Routes, RouterModule, PreloadAllModules } from '@angular/router';
import { FormComponent } from './form/form.component';
import { HomeComponent } from './home/home.component';
import {ThanksComponent} from './form/thanks/thanks.component';
const rootPath = (window as any).PROJECT_URL ? 'form/' : ':project/';
const routes: Routes = [
  {
    path: '',
    component: HomeComponent
  },
  {
    path: 'auth',
    loadChildren: () => import('./auth/auth.module').then(m => m.AuthModule)
  },
  {
    path: `${rootPath}:form`,
    component: FormComponent
  },
  {
    path: `${rootPath}:form/submission`,
    loadChildren: () => import('./form/submission/submission.module').then(m => m.SubmissionModule)
  },
  {
    path: `${rootPath}:form/thanks`,
    component: ThanksComponent
  },
  {
    path: `${rootPath}:form/offline`,
    loadChildren: () => import('./offline/offline.module').then(m => m.OfflineModule)
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    useHash: true,
    preloadingStrategy: PreloadAllModules
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
