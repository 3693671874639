import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import { ToastrModule } from 'ngx-toastr';
import { FormioModule, FormioAppConfig } from '@formio/angular';
import { FormioGrid } from '@formio/angular/grid';
import { FormioResources } from '@formio/angular/resource';
import { FormioAuthService, FormioAuthConfig } from '@formio/angular/auth';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { FormComponent } from './form/form.component';
import { AuthService } from './AuthService';
import { AppConfig } from './config';
import {OfflineService} from './offline/offline.service';
import { HomeComponent } from './home/home.component';
import 'jquery/dist/jquery.slim';
import 'bootstrap';
import { ThanksComponent } from './form/thanks/thanks.component';
import { AppService } from './app.service';

// Enable once we resolve the problem with ServiceWorkers and offline mode.
import { ServiceWorkerModule, SwRegistrationOptions } from '@angular/service-worker';
import { environment } from '../environments/environment';
import {TranslateModule} from '@ngx-translate/core';

@NgModule({
  declarations: [
    AppComponent,
    FormComponent,
    HomeComponent,
    ThanksComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    /* TODO: Need to figure out why the service workers conflict with offline mode. */
    ServiceWorkerModule.register('./ngsw-worker.js', { enabled: environment.production }),
    ToastrModule.forRoot(),
    AppRoutingModule,
    FormioModule,
    FormioGrid,
    TranslateModule.forRoot({
      defaultLanguage: 'en'
    })
  ],
  providers: [
    AppConfig,
    AppService,
    OfflineService,
    FormioResources,
    {provide: FormioAuthService, useClass: AuthService },
    {provide: FormioAppConfig, useClass: AppConfig},
    {provide: FormioAuthConfig, useValue: {
      delayAuth: true,
      login: {
        form: 'user/login'
      }
    }}
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }

