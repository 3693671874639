import {
  Component,
  OnInit,
  AfterViewInit,
  EventEmitter,
  ViewEncapsulation,
  ViewChildren, QueryList
} from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormioAuthService } from '@formio/angular/auth';
import {Formio, FormioComponent} from '@formio/angular';
import { AppConfig } from '../config';
import _get from 'lodash/get';
import _last from 'lodash/last';
import { ToastrService } from 'ngx-toastr';
import { OfflineService } from '../offline/offline.service';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-form',
  templateUrl: './form.component.html',
  styleUrls: ['./form.component.scss', '../../assets/lib/formiojs/formio.full.min.css'],
  encapsulation: ViewEncapsulation.None
})
export class FormComponent implements OnInit, AfterViewInit {
  @ViewChildren(FormioComponent) formInstance: QueryList<FormioComponent>;
  public submission: any = {data: {}};
  public canSubmit = false;
  public formLoading = true;
  public submissionComplete = false;
  public isWatchSubErrors = true;
  public error: EventEmitter<object> = new EventEmitter();
  public success: EventEmitter<object> = new EventEmitter();
  public options: any = { disableAlerts: true, noAlerts: false};
  public languages = [];
  public currentLang = 'en';
  constructor(
    public route: ActivatedRoute,
    public router: Router,
    public config: AppConfig,
    public auth: FormioAuthService,
    public toastr: ToastrService,
    public offlineService: OfflineService,
    public translateService: TranslateService
  ) {
  }

  ngOnInit() {
    const data = this.parseUrlQuery();
    this.submission = {data};
    this.route.params.subscribe(() => this.config.loadForm(false, this.route).then((form) => {
      this.formLoading = false;
      if (form) {
        this.canSubmit = true;
      }
    }).catch((err) => {
      this.formLoading = false;
      this.toastr.error(err.message || err);
    }));
  }

  ngAfterViewInit() {
    this.submissionComplete = false;
    this.formInstance.changes.subscribe(instance => {
      if (instance?.first) {
        this.languages = [];
        if (this.config.translations?.length > 0) {
          this.config.translations.forEach(translation => {
            this.languages.push(translation.data.language);
            instance.first.formio.addLanguage(translation.data.language, translation.data.translations);
          });
        }
        this.currentLang = localStorage.getItem('lang') ? localStorage.getItem('lang') : this.config.lang;
        instance.first.formio.language = this.currentLang;
      }
    });
  }

  private parseUrlQuery() {
    const query = Formio.pageQuery();
    delete query.paths;
    return query;
  }

  onChange(event: any) {
    if (
      event && event.data &&
      this.config.currentForm &&
      this.config.currentForm.properties &&
      this.config.currentForm.properties.owner
    ) {
      // If they provide an owner setting, then set the owner of this submission to what they configured.
      this.submission.owner = _get(event, this.config.currentForm.properties.owner);
    }
  }

  onSubmit(submission: any) {
    this.config.formio.saveSubmission(this.config.submitAlter(submission)).then((saved: any) => {
      const isOnline = this.offlineService.isOnline;
      this.success.emit();
      this.config.parent.send({formSubmission: saved});
      if (this.config.query.return) {
        const hasQuery = !!this.config.query.return.match(/\?/);
        const isOrigin = this.config.query.return.indexOf(location.origin) === 0;
        this.config.query.return += hasQuery ? '&' : '?';
        this.config.query.return += 'sub=' + saved._id;
        if (!isOrigin) {
          this.config.query.return += '&form=' + encodeURIComponent(this.config.formio.formUrl);
        }
        window.location.href = this.config.query.return;
        if (isOrigin) {
          window.location.reload();
        }
      } else if (this.auth.authenticated) {
        this.config.getPlan().then((plan: string) => {
          if (['team', 'commercial'].indexOf(plan) < 0) {
            this.toastr.warning('Limited Pro Version only Allow Anonymous Submissions, please contact support@form.io');
            this.router.navigate([`/${this.config.path}`]);
          } else {
            this.router.navigate([`submission/${saved._id}`], {relativeTo: this.route});
          }
        });
      } else {
        this.submissionComplete = true;
        this.config.needsRefresh = true;
      }

      if (
        saved
        && isOnline
        && this.config.offlinePlugin
        && this.config.offlinePlugin.Formio
        && this.config.offlinePlugin.Formio.forms
      ) {
        const formio = _last(Object.values(this.config.offlinePlugin.Formio.forms));

        if (formio) {
          formio.emit('submitDone', saved);
        }
      }
    }).catch((err) => {
      this.error.emit(err);
    });
  }

  translate(lang) {
    if (this.formInstance?.first.formio) {
      this.translateService.use(lang);
      this.currentLang = lang;
      localStorage.setItem('lang', lang);
      this.formInstance.first.formio.language = lang;
    }
  }
}
