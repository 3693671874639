import { Injectable, ApplicationRef, EventEmitter } from '@angular/core';
import { Formio } from '@formio/angular';
import { AppConfig } from '../config';
import { timer } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { OfflinePlugin } from '@formio/offline-plugin';

@Injectable()
export class OfflineService {
  public checkOnlineTimer;
  public sync = false;
  public offlinePlugin;
  public isOnline = true;
  public isReady = false;
  public onlineChange: EventEmitter<any> = new EventEmitter();
  public offlineCount = 0;
  public offlineOps: any = {};
  public offlineSubmissions = [];
  constructor(
    public appConfig: AppConfig,
    public appRef: ApplicationRef,
    public toastr: ToastrService
  ) {
    this.checkOnlineTimer = timer(1000, 1000);
    Formio.use(OfflinePlugin(`offline-${Formio.namespace}`, appConfig.appUrl));
    this.offlinePlugin = appConfig.offlinePlugin = Formio.getPlugin(`offline-${Formio.namespace}`);
    Formio.events.on('offline.formError', (err) => {
      this.sync = false;
      let list = '';
      let errorList = '';
      if (err.details?.length > 0) {
        err.details.map((error) => {
          errorList += `<li>${error.message}</li>`;
        });
        list = `<ol>${errorList}</ol>`;
      } else {
        list = err.name;
      }
      this.toastr.error(list, 'Please fix the following errors before submitting: ', {enableHtml: true});
    });
    Formio.events.on('offline.saveQueue', () => this.updateOfflineCount());
    Formio.events.on('offline.queue', () => this.updateOfflineCount());
    Formio.events.on('offline.queueEmpty', () => {
      this.sync = false;
      this.updateOfflineCount();
    });
    this.offlinePlugin.ready.then(() => {
      this.isReady = true;
    });

    // Only register the timer when the application is stable, otherwise the offline mode service worker fails to load.
    this.appRef.isStable.subscribe(() => {
      this.checkOnlineTimer.subscribe(() => this.checkOnlineStatus());
    });

    this.updateOfflineCount();
  }

  updateOfflineCount() {
    this.offlinePlugin.ready.then(() => {
      // If we are offline, then check the submission queue length.
      this.offlineCount = this.offlinePlugin.submissionQueueLength();
      this.offlineOps = {};
      if (this.offlineCount > 0) {
        this.offlineSubmissions = this.offlinePlugin.submissionQueue.map((queued) => {
          this.offlineOps[queued.request._id] = queued.request.method;
          return queued.request;
        });
      } else {
        this.offlineSubmissions = [];
      }
    });
  }

  emptyQueue() {
    return this.offlinePlugin.emptySubmissionQueue();
  }

  clearAll() {
    return this.offlinePlugin.clearAll();
  }

  clearOfflineSubmissions() {
    return this.offlinePlugin.clearOfflineSubmissions();
  }

  dequeueSubmissions() {
    this.sync = true;
    this.offlinePlugin.ready.then(() => {
      this.offlinePlugin.dequeueSubmissions().then(() => {
        this.clearAll();
      });
    });
  }

  checkOnlineStatus() {
    if (this.isOnline !== navigator.onLine) {
      this.onlineChange.emit(navigator.onLine);
    }
    this.isOnline = navigator.onLine;
  }
}
